import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeaderOneColumn from "../assets/elements/StandardHeaderOneColumn";
import Cta from "../partials/Cta";
import SEO from "../components/seo";

import UltrasoundSegmentationDemo from "../../static/media/ultrasound_segmentation.webm";
import UltrasoundBLines from "../../static/media/ultrasound_b_lines.webm";

import LogoCloud from "../assets/elements/LogoCloud";
import { StaticImage } from "gatsby-plugin-image";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import { Script } from "gatsby";
import CaseStudyMSK from "../partials/CaseStudyMSK";
import { EncordGradientText } from "../utilities/DesignUtilities";
import CustomLink from "../components/CustomLink";
import VideoComponent from "../components/VideoComponent";

export default function Ultrasound({ location }) {
  return (
    <Layout location={location}>
      <LeftColours />
      <RightColours />

      <StandardHeaderOneColumn
        title={
          <>
            Build <EncordGradientText text={"Ultrasound AI Models"} /> 6x Faster
            With Encord
          </>
        }
        location={location}
        pageName="Ultrasound AI Page"
        description={
          "Create more accurate ultrasound diagnostic models with Encord’s collaborative annotation tools and clinical data operations platform."
        }
        heroGraphic={
          <>
            <VideoComponent loop autoPlay muted className="rounded-lg">
              <source
                src={UltrasoundSegmentationDemo}
                type="video/webm"
                width="768"
                height="432"
              />
            </VideoComponent>
          </>
        }
      />

      <LogoCloud
        title={
          "Trusted by innovative and industry-leading healthcare institutions"
        }
        pb={10}
        pbMd={10}
        numImages={5}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/1716e248-7485-43e9-9454-bb17670cd7d4_stanford-medicine-logo-vector.png?auto=compress,format"
            }
            alt={"Stanford Medicine Logo"}
            className={"h-24 w-32"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/8ed83228-e834-494f-84d4-06f0b698c5d6_logo_uhn.svg"
            }
            alt={"UHN logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageThree={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/ab584df2-0365-496d-a450-9df08c58fade_Memorial+Sloan+Kettering+Cancer+Center-1.svg"
            }
            alt={"MSK logo"}
            className={"h-20 w-44"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFour={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/aa834409-1594-49f1-872e-d6b0ec856626_NHS_gstt.svg"
            }
            alt={"St Thomas logo"}
            className={"h-24 w-36 lg:w-40"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFive={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/f3a7d750-8fcf-4a89-9212-59a427503a98_klc.svg"
            }
            alt={"KCL logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"How it works"}
        header={
          "Collaborative Annotation and Segmentation for Ultrasound Training Data"
        }
        description={
          <>
            <p>
              Encord is a medical-grade image & video annotation and clinical
              operations platform designed for ultrasound AI use cases,
              including traditional bedside US and point-of-care (POCUS)
              ultrasound.
            </p>
            <br />
            <p>
              Encord makes video & image labeling quicker and more efficient,
              reducing the hours your ultrasound experts need to spend
              annotating and reviewing videos. And Encord’s advanced labeling
              protocols and data quality features ensure the best training data
              is being used to train your ultrasound machine learning models.
            </p>
            <br />
            <p>
              No matter the aim of your ultrasound model (AI-assisted plane
              finding, automatic measurements, B-Line detection, or anything
              else) Encord can help you build better models faster.
            </p>
          </>
        }
        features={[]}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <VideoComponent loop autoPlay muted className="rounded-lg">
            <source
              src={UltrasoundBLines}
              type="video/webm"
              width="768"
              height="432"
            />
          </VideoComponent>
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/ultrasound_annotation.webp"}
            width={450}
            alt="Segmentation of tumor in ultrasound video"
            className="rounded-lg"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/ultrasound_ontology.webp"}
            width={450}
            alt="Ontology of tumor, malignant tumor, and artefact in ultrasound"
            className="rounded-lg"
          />
        }
        headerOne={"Efficient Medical Grade Ultrasound Video Labeling"}
        headerTwo={"A Single Platform for Collaborative Annotation"}
        headerThree={"The Industry’s Most Powerful Labeling Protocols"}
        descriptionOne={
          <>
            <p>
              Encord is designed to work with your skilled ultrasound annotators
              and reviewers, saving them time and delivering a better
              experience.
            </p>
            <br />
            <p>
              Object tracking, interpolation and AI-assisted labeling makes
              annotation easier and quicker. And the annotation hotkey controls
              are fully customizable so you can create an easy to use interface
              for your annotation team.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Give your ultrasound annotators a single platform for all types of
              annotation, including polygon, polyline, bounding box, keypoint,
              segmentation and classification.
            </p>
            <br />
            <p>
              And a single platform means your developers don’t need to maintain
              multiple custom or open-source annotation tools.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Encord supports rich labeling protocols within its ultrasound
              annotation tool.
            </p>
            <br />
            <p>
              You can create complex labeling protocols covering as many feature
              types as you need to deliver the right ultrasound training data to
              your machine learning teams.
            </p>
          </>
        }
      />

      <section className="pt-12 pb-12 border-t border-gray-200 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl px-4 mx-auto sm:px-6">
          <CaseStudyMSK includeCTALink />
        </div>
      </section>

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeTopBorder
        includeTopPadding
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../../static/media/ultrasound_clinical_ops.webp"}
            width={600}
            alt="Expert review quality control for ultrasound"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_accuracy.webp"}
            width={550}
            alt="Encord improves the quality of your ultrasound training data"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_approval.webp"}
            width={250}
            alt="Encord supports FDA clearance and CE marking processes"
          />
        }
        headerOne={"Streamline Your Clinical Data Operations"}
        headerTwo={"Deliver Better Ultrasound Training Data"}
        headerThree={"Supporting Your Regulatory Journey"}
        descriptionOne={
          <>
            <p>
              Make your POCUS annotator management processes more efficient with
              role-based access control, worklist support and configurable
              expert review pipelines.
            </p>
            <br />
            <p>
              It means you can ensure your most skilled ultrasound annotators’
              time isn’t being wasted on the wrong tasks.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord helps clinical operations teams identify dataset errors,
              biases and imbalances (and even monitor individual annotator
              performance).
            </p>
            <br />
            <p>
              This allows clinical operations to provide better datasets to
              machine learning teams and reduce the time it takes to create an
              ultrasound machine learning model.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Encord has been designed to make it easier for you to meet your
              regulatory submissions, including FDA and CE approval.
            </p>
            <br />
            <p>
              In addition to being{" "}
              <CustomLink
                className="font-medium text-gray-600 hover:text-gray-900"
                to={"/security/"}
              >
                HIPAA and SOC2
              </CustomLink>{" "}
              compliant, Encord’s data pipelines, fully auditable labels and
              quality control and assurance features make regulatory compliance
              much less of a headache.
            </p>
          </>
        }
      />

      <Cta
        location={location}
        pageName={"Ultrasound Page"}
        ctaText={"Powerful tools to accelerate machine learning"}
      />
    </Layout>
  );
}

export const Head = () => (
  <>
    <Script id="g2crowd-tracking-script" defer>
      {`(function (c, p, d, u, id, i) {
  id = ''; // Optional Custom ID for user in your system
  u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
  i = document.createElement('script');
  i.type = 'application/javascript';
  i.async = true;
  i.src = u;
  d.getElementsByTagName('head')[0].appendChild(i);
}("1008306", document.location.href, document));`}
    </Script>
    <SEO
      title="AI Training Data Platform for Ultrasound Computer Vision | Encord"
      description="We're helping leading healthcare institutions accelerate AI development for ultrasound with collaborative video & native DICOM annotation tools."
    >
      <meta name="robots" content="noindex, nofollow" />
    </SEO>
  </>
);
